import React from 'react';
import { Helmet } from 'react-helmet';

import ComingSoon from 'components/ComingSoon';
import Layout from 'components/layout';

const AndroidPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Android</title>
      <meta name="og:title" content="Android" />
      <meta name="twitter:title" content="Android" />
    </Helmet>
    <ComingSoon os="android" />
  </Layout>
);

export default AndroidPage;
