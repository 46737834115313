import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import styles from './ComingSoon.module.scss';

interface ComingSoonProps {
  os: 'ios' | 'android';
}
const ComingSoon: React.FC<ComingSoonProps> = ({ os }) => {
  const {
    androidImage,
    iosImage,
  } = useStaticQuery(
    graphql`
        query {
          androidImage: file(relativePath: { eq: "android.png" }) {
                childImageSharp {
                    fluid(maxWidth: 451) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
          iosImage: file(relativePath: { eq: "iphone.png" }) {
            childImageSharp {
              fluid(maxWidth: 451) {
                ...GatsbyImageSharpFluid_withWebp_noBase64,
              }
            }
          }
        }
    `,
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          {os === 'android'
            ? <GatsbyImage fluid={androidImage.childImageSharp.fluid} />
            : <GatsbyImage fluid={iosImage.childImageSharp.fluid} />}
        </div>
        <div className={styles.contentContainer}>
          <h2 className={styles.title}>
            {os === 'android'
              ? <FormattedMessage id="components.android" />
              : <FormattedMessage id="components.ios" />}
          </h2>
          <div className={styles.separator} />
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
